/* src/components/Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.modal-content {
    background: #1f1f1f;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
}  