@import url('https://fonts.googleapis.com/css2?family=Share+Tech+Mono&display=swap');

body {
  background-color: #121212; /* Dark background */
  color: #9a8866; /* Light text */
  font-family: 'Share Tech Mono'; /* Your chosen font */
  margin: 0;
  padding: 0;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
  height: 100%;
}

button {
  background-color: #333; /* Dark button background */
  color: #fc0; /* Button text color */
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s; /* Smooth transition */
  font-family: 'Share Tech Mono';
}

button:hover {
  background-color: #fc0; /* Change on hover */
  color: #121212; /* Change text color on hover */
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Optional: for rounded corners */
}

h3, h2 {
  color: #9a8866; /* Light headings */
  text-align: center;
}