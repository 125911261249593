h1 {
  text-align: center;
}

a {
  text-decoration: underline;
  color: #9a8866;
}

.start-game-button {
  display: block;           /* Change display to block */
  margin: auto;          /* Center horizontally */
  padding: 10px 20px;      /* Add padding */
  background-color: #1f1f1f; /* Button background */
  color: #9a8866;             /* Button text color */
  border: none;            /* No border */
  border-radius: 5px;      /* Rounded corners */
  cursor: pointer;         /* Pointer cursor on hover */
  transition: background-color 0.3s; /* Smooth transition */
}

.start-game-button:hover {
  background-color: #9a8866;  /* Change on hover */
  color: #121212;          /* Change text color on hover */
}

.container { /* Assuming you have a container class */
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;     /* Center content vertically (if needed) */
}

.screenshot-container {
  display: flex;               /* Use flexbox for centering */
  justify-content: center;     /* Center horizontally */
  align-items: center;         /* Center vertically */
  margin: 20px;               /* Add some margin for spacing */
}

.screenshot {
  max-width: 90%;             /* Limit the width to 90% of the container */
  max-height: 60vh;           /* Limit the height to 60% of the viewport height */
  object-fit: contain;        /* Maintain aspect ratio */
  border: 2px solid #9a8866;     /* Optional: add a border for better visibility */
  border-radius: 5px;         /* Optional: round corners */
}

.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.button-container {
  display: flex;               /* Use flexbox for layout */
  justify-content: center;     /* Center buttons horizontally */
  margin: 10px 0;             /* Add some margin for spacing */
}

.button-container button {
  margin: 0 10px;             /* Optional: add horizontal spacing between buttons */
  padding: 10px 20px;         /* Adjust padding for better button appearance */
  background-color: #333;     /* Example background color */
  color: #9a8866;                /* Text color */
  border: none;               /* Remove default border */
  border-radius: 5px;        /* Optional: round corners */
  cursor: pointer;            /* Change cursor on hover */
  transition: background-color 0.3s; /* Add transition effect */
}

.button-container button:hover {
  background-color: #555;     /* Change background color on hover */
}

.map-container {
  display: flex;               /* Use flexbox for layout */
  justify-content: center;     /* Center content horizontally */
  margin: 20px 0;             /* Add some margin for spacing */
}

.submit-button-container {
  display: flex;               /* Use flexbox for layout */
  justify-content: center;     /* Center button horizontally */
  margin: 20px 0;             /* Add margin for spacing */
}

.map-grid {
  margin-bottom: 5vh;
}

.message-container {
  margin: 20px 0;
  font-size: 16px;
  text-align: center;
}

/* General footer styles */
.footer {
  background-color: #1a1a1a;
  color: #fff;
  text-align: center;
  padding-bottom: 2vh;
  padding-bottom: 2vh;
  width: 100%;
}

/* Pin footer to the bottom of the viewport on the landing page */
.landing-page .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

/* When the game starts, footer should behave normally */
.App:not(.landing-page) {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App:not(.landing-page) .footer {
  margin-top: auto; /* Ensures the footer stays at the bottom when content grows */
}

.donate-button {
  display: block;
  margin: auto;
  padding: 10px 20px;
  background-color: #1f1f1f;
  color: #9a8866;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: block;
  margin: auto;
  padding: 10px 20px;
  background-color: #1f1f1f;
  color: #9a8866;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.donate-button:hover {
  background-color: #9a8866;
  color: #121212;
  background-color: #9a8866;
  color: #121212;
}

.donate-button-container {
  margin-top: 10px;
}